import React from "react";
import moment from "moment";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import {calculateCotizaciones, decimalAdjust} from "../../Global";
import printA4, {crearDocPDF} from "../../helpers/A4";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    GetPrecioCosto, getPrecioPorMayor
} from "../../componentes/Preventas/PreciosPreventa";
import printTicket from "./Ticket";
import Modal from "../../componentes/Modal";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";

export default class Cotizaciones extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            cliente: {},
            isLoading: false,
            cotizaciones: [],
            extImg: 'png',
            showEmailModal: false,
            selectedCot: {},
            email: "",
            cuentasBancarias: [],
        }

        this.onChangeCliente = this.onChangeCliente.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.fetchCotizaciones = this.fetchCotizaciones.bind(this);
        this.onPrintPdf = this.onPrintPdf.bind(this);
        this.onPrintTicket = this.onPrintTicket.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.fetchCuentasBancarias = this.fetchCuentasBancarias.bind(this);
    }

    componentDidMount() {
        this.fetchCuentasBancarias();
        this.fetchCotizaciones();
    }

    async fetchCuentasBancarias() {
        this.setState({isLoading: true});
        let res = await fetch('/api/cuentas/');
        if (res.ok)
            this.setState({cuentasBancarias: await res.json()})
        this.setState({isLoading: false});
    }

    searchClients(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/clientes/buscar/?query=${query}`)
                .then(r => r.json())
                .then(clientes => {
                    const mappedClientes = clientes.map(c => {
                        return {
                            label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                            value: c.IdCliente
                        }
                    });
                    resolve(mappedClientes)
                }).catch(reject)
        })
    }

    onChangeCliente(test) {
        this.setState({
            cliente: test
        })
    }

    onChangeFecha(e) {
        const elem = e.target;
        this.setState((state, props) => {
            let newState = {...state};
            newState[elem.getAttribute('name')] = elem.value
            return newState;
        })
    }

    async fetchCotizaciones() {
        this.setState({isLoading: true})
        const idCliente = this.state.cliente && this.state.cliente.value ? this.state.cliente.value : "";
        const query = `fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&idCliente=${idCliente}&estado=GENERADO`;
        let resCotizaciones = await fetch(`/api/cotizaciones/?${query}`);
        let data = await resCotizaciones.json();
        this.setState({isLoading: false, cotizaciones: data.cots, extImg: data.extImg})
    }

    onDetalleCotizacion(cotizacion) {
        this.props.history.push(`/cotizaciones/detalle/${cotizacion.id}`)
    }

    async onPrintPdf(cotizacion) {
        this.setState({isLoading: true})
        const [venta, detsProd] = await this.fetchAndMappData(cotizacion);
        await printA4(detsProd, venta, this.state.extImg, this.state.cuentasBancarias);
        this.setState({isLoading: false})
    }

    async onPrintTicket(cotizacion) {
        this.setState({isLoading: true})
        const [venta, detsProd] = await this.fetchAndMappData(cotizacion);
        await printTicket(detsProd, venta, this.state.extImg);
        this.setState({isLoading: false})
    }

    openEmailModal(cotizacion) {
        this.setState({showEmailModal: true, selectedCot: cotizacion, email: cotizacion.CorreoElectronico})
    }

    async fetchAndMappData(cotizacion) {
        let res = await fetch(`/api/cotizaciones/detalles/${cotizacion.id}`)
        let data = await res.json();
        let detsProd = data.dets.map((dp, i) => ({
            ...dp,
            PrecioVenta: dp.precioVenta,
            IdTipoStock: dp.IdTipoStock,
            Tributos: dp.Tributos,
            TasaISC: dp.TasaISC,
            PrecioEspecial: FindPrecioEspecial([], dp),
            PrecioFamiliar: FindPrecioFamiliar([], dp),
            PrecioCosto: GetPrecioCosto([], dp),
            PrecioMenor: FindPrecioMenor([], dp),
            oldPrecios: [],
            precioMayor: getPrecioPorMayor([], dp),
            Cantidad: dp.cantidad,
            Total: dp.total,
            Importe: dp.total,
            Unidad: dp.unidadMedida,
            Descuento: 0,
            Indice: i + 1,
        }))

        const totales = calculateCotizaciones(detsProd);

        let venta = {
            ...cotizacion,
            FechaEmision: cotizacion.fechaEmision,
            RazonSocial: cotizacion.razonSocial,
            Descripcion: "Cotización",
            Observacion: cotizacion.descripcion,
            Serie: cotizacion.serie,
            NumeroComprobante: cotizacion.id,
            Redondeo: 0,
            Gravadas: totales.gravados,
            Exoneradas: totales.exonerados,
            Inafectas: totales.inafectos,
            ICBPER: totales.icbper,
            IGV: decimalAdjust('floor', totales.gravados * 0.10, -2),
            Exportacion: 0,
            Total: totales.total,
            Direccion: cotizacion.Direccion || "",
            Gratuitas: 0,
            ISC: 0,
            IVAP: 0,
            DescuentoTotal: 0,
            TotalRedondeo: totales.total,
            infoCotizaciones: ["Esta cotización es válida hasta agotar stock.",
                "Los precios pueden cambiar sin previo aviso."]
        }

        return [venta, detsProd];
    }

    onCloseModal() {
        this.setState({showEmailModal: false})
    }

    onKeyUpModal(e) {
        if (e.key === "Escape")
            this.setState({showEmailModal: false})
    }

    async sendEmail(e) {
        e.preventDefault();
        if (this.state.email && this.state.email.length) {
            this.setState({isLoading: true})
            const [venta, detsProd] = await this.fetchAndMappData(this.state.selectedCot);
            let data = await this.getDataForSend(detsProd, venta);
            let res = await fetch('/api/cotizaciones/send', {
                method: 'post',
                headers: {
                    "Accept": "application/json",
                },
                body: data
            })
            if (res.ok)
                notificarMsg('Se está enviando el correo.', 'success')
            else
                notificarError('Ha ocurrido un error al momento de enviar el correo.')
            this.setState({isLoading: false, showEmailModal: false})
        } else
            notificarError('Debe ingresar un email.')
    }

    async getDataForSend(detsProd, venta) {
        let docpdf = await crearDocPDF(detsProd, venta, this.state.extImg);
        let blob = await docpdf.output('blob');
        let data = new FormData();
        data.append('email', this.state.email);
        data.append('idCotizacion', this.state.selectedCot.id)
        data.append('pdf', blob, 'venta_pdf.pdf')
        return data;
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Cliente
                    </div>
                    <div className="col-sm">
                        <AsyncSelect
                            defaultOptions={true}
                            onChange={this.onChangeCliente}
                            value={this.state.cliente}
                            loadOptions={this.searchClients}
                            isClearable={true}
                            isLoading={this.state.isLoading}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Fecha inicio
                    </div>
                    <div className="col-sm">
                        <input onChange={this.onChangeFecha} type="date" disabled={this.state.isLoading}
                               name="fechaInicio" className="form-control"
                               value={this.state.fechaInicio}/>
                    </div>
                    <div className="col-sm-auto">
                        Fecha fin
                    </div>
                    <div className="col-sm">
                        <input onChange={this.onChangeFecha} type="date" disabled={this.state.isLoading}
                               name="fechaFin" className="form-control"
                               value={this.state.fechaFin}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <button onClick={this.fetchCotizaciones} disabled={this.state.isLoading}
                                className="btn btn-outline-primary">
                            Filtrar
                        </button>
                    </div>
                    <div className="col-sm">
                        <a className="float-right" href="#" onClick={e => {
                            e.preventDefault();
                            this.props.history.push('/cotizaciones/crear')
                        }}>
                            Crear cotización
                        </a>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <ReactTable
                            data={this.state.cotizaciones}
                            filterable
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Serie-Correlativo",
                                            id: "serieNum",
                                            width: "180",
                                            accessor: d => d.serieNum,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["serieNum"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Cliente",
                                            id: "razonSocial",
                                            width: "180",
                                            accessor: d => d.razonSocial,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["razonSocial"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Importe total",
                                            id: "total",
                                            width: "150",
                                            filterable: true,
                                            Cell: (d) => decimalAdjust('floor', d.original.total, -2)

                                        },
                                        {
                                            Header: "Acciones",
                                            width: "400",
                                            filterable: false,
                                            Cell: (d) => {
                                                return (
                                                    <div className="btn btn-group">
                                                        <button onClick={() => this.onDetalleCotizacion(d.original)}
                                                                className="btn btn-outline-info"
                                                                disabled={this.state.isLoading}>
                                                            Detalle
                                                        </button>
                                                        <button onClick={() => this.onPrintPdf(d.original)}
                                                                className="btn btn-outline-danger"
                                                                disabled={this.state.isLoading}>
                                                            PDF
                                                        </button>
                                                        <button onClick={() => this.onPrintTicket(d.original)}
                                                                className="btn btn-outline-dark"
                                                                disabled={this.state.isLoading}>
                                                            Ticket
                                                        </button>
                                                        <button onClick={() => this.openEmailModal(d.original)}
                                                                className="btn btn-outline-secondary"
                                                                disabled={this.state.isLoading}>
                                                            Enviar Correo cliente
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>

                <Modal isOpen={this.state.showEmailModal} onClose={this.onCloseModal} title={"Enviar correo"}
                       handleKeyUp={this.onKeyUpModal}>
                    <div style={{width: "300px", height: "100px"}}>
                        <form method="post">
                            <div className="form-group">
                                <input type="email" className="form-control mr-2"
                                       onChange={e => this.setState({email: e.target.value})}
                                       value={this.state.email} required="required"/>
                                <button disabled={this.state.isLoading} onClick={this.sendEmail}
                                        className="btn btn-primary mt-2 mr-2 mt-2">
                                    Enviar
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>

            </div>
        )
    }
}
