import React from 'react';
import {defaultGetHeaders, pfetch} from "../helpers/wrappers";
import {notificarError} from "./Almacenes/AlmacenNotify";

export default class PriceConsulter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            prods: [],
            search: "",
            cleanSearch: true,
            cleaner: null
        }
        const metadata = JSON.parse(window.localStorage.getItem('extraConf'))
        this.extImg = metadata.extImg
        this.ruc = metadata.ruc
        this.fetchProds = this.fetchProds.bind(this)
        this.onSubmitSearch = this.onSubmitSearch.bind(this)
    }

    componentDidMount() {
        this.fetchProds(this.state.search)
    }

    fetchProds(search) {
        pfetch({
            fetch: {
                url: `/api/prices/?search=${search}`,
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true}),
            then: (data) => this.setState({
                prods: data.map(d => {
                    return {
                        ...d,
                        precio: Boolean(d.afectacionFree) ? d.precio : d.precio * 1.10
                    }
                })
            }),
            finally: () => this.setState({isLoading: false, search: this.state.cleanSearch ? '' : this.state.search}),
            error: () => notificarError("No se puedieron encontrar los productos")
        })
    }

    componentWillUnmount() {
        clearTimeout(this.state.cleaner)
    }

    onSubmitSearch(e) {
        e.preventDefault()
        this.fetchProds(this.state.search)
        this.setState((state) => {
            if (state.cleaner)
                clearTimeout(state.cleaner)
            return {
                cleaner: setTimeout(() => this.fetchProds(''), 5000)
            }
        })
    }

    render() {
        const {isLoading, prods, search, cleanSearch} = this.state
        return (
            <div className="mx-4">

                <div className="container mt-3">
                    <div className="row height d-flex justify-content-center align-items-center">
                        <div className="col-sm-auto">
                            <img className="img-fluid" width="204" height="126"
                                 src={`${process.env.REACT_APP_FACT_BASE_URL}/datos/${this.ruc}/Img/logo_${this.ruc}.${this.extImg}`}
                                 alt=""/>
                        </div>
                        <div className="col-sm-auto">
                            <h1>Consultador de precios</h1>
                        </div>
                    </div>
                </div>

                <div className="container mt-3">
                    <div className="row height d-flex justify-content-center align-items-center">
                        <form style={{display: "contents"}} onSubmit={this.onSubmitSearch}>
                            <div className="col-sm-auto">
                                <input style={{width: "40rem"}}
                                       placeholder="Ingrese el nombre o código del producto y luego presione enter"
                                       type="text"
                                       className="form-control" value={search} onChange={e => {
                                    const val = e.target.value
                                    this.setState({search: val})
                                }}/>
                            </div>
                            <div className="col-sm-auto">
                                <button type="submit" className="btn btn-outline-primary">Buscar</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="container mt-3">
                    <div className="row height d-flex justify-content-center align-items-center">
                        <div className="col-sm-auto">
                            Limpiar texto de búsqueda al momento de buscar
                            <input className="ml-2" type="checkbox" checked={cleanSearch} onChange={e => {
                                const checked = e.target.checked
                                this.setState({
                                    cleanSearch: checked
                                })
                            }}/>
                        </div>
                    </div>
                </div>

                <div className="container mt-3">
                    <div className="row">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"/>
                            </div>
                        ) : (
                            <div className="col-sm-12">
                                {prods.length === 1 ? (
                                    <div className="row py-lg-5">
                                        <div className="col-lg-6 col-md-8 mx-auto">
                                            <h3 className="fw-light" style={{textAlign: "center"}}>{prods[0].descripcion}</h3>
                                            <h1 className="generar__boton" style={{
                                                textAlign: "center",
                                                backgroundColor: "#01a59c",
                                                color: "white"
                                            }}>
                                                {prods[0].precio.toFixed(6)} S./
                                            </h1>
                                        </div>
                                    </div>
                                ) : (
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th>Producto</th>
                                            <th>Precio S:/</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {prods.map((p, i) => <tr key={i}>
                                            <td style={{fontSize: "1.5rem"}}>{p.descripcion}</td>
                                            <td style={{fontSize: "1.5rem"}}>{Number(p.precio).toFixed(6)}</td>
                                        </tr>)}
                                        {prods.length === 0 ? <tr>
                                            <td><strong>No se encontraron productos</strong></td>
                                        </tr> : null}
                                        </tbody>
                                    </table>
                                )}

                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
